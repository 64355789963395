body {
    background-color: var(--darkest);
}
.shi-page {
    padding-top: 5vh;
    color: var(--lightest);
    background-color: var(--darkest);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    overflow: hidden;
}

.questions-form-container {
    height: 5vh;
    width: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.add-question-visible-div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(251, 183, 26);
    width: 100vh;
    height: 5vh;
    font-size: 2rem;
    color: var(--darkest);
}



.num-input {
    width: 5vh;
    height: 5vh;
}

.question-input {
    width: 90vh;
    height: 5vh;
    margin-left:4vh;

}

.QA-div {
    padding: 2vh;
    display: flex;
    flex-direction: row;
    transition: transform 0.2s ease;
    font-size: 1.3rem;
    border-radius: 30px;

;
}

.QA-div:hover {
    transform: scale(1.07);
}


button {
    font-size: 1.3rem;
height: 5vh;
width: 20vh;
margin: 2vh;
background-color: var(--lightest);
transition: transform 0.2s ease;
border-radius: 10px;
}

button:hover {
    box-shadow:
    11px 10px 14px rgba(0, 0, 0, 0.2);
background-color: var(--accentLight);

}
.text-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 30px;
    box-shadow:
    11px 10px 14px rgba(0, 0, 0, 0.2);
}

.big-num {
    height: 15vh;
    width: 10vh;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    margin-right: 1vh;
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dark);
    /* border: solid 1px white; */
    box-shadow:
    11px 10px 14px rgba(0, 0, 0, 0.2);
}

.question-div {
    background-color: var(--lightest);
    color: var(--darkest);
    width: 80vh;
    height: 4vh;
    border-top-right-radius: 30px;
    display: flex;
    align-items: center;
    box-shadow:
    11px 10px 14px rgba(0, 0, 0, 0.2);
}

.answer-div {
    background-color: rgb(251, 183, 26);
    ;
    color: var(--darkest);
    width: 80vh;
    height: 10vh;
    border-bottom-right-radius: 30px;
    display: flex;
    align-items: center;
    overflow-y: scroll;

}

