.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: var(--darkest);
    color: var(--light);
    overflow: hidden;
}   

.button-div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 40vw;
    height: 90vh;
}

.comment-container, Card.Group {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 40vw;
    height: 100vh;
    overflow: hidden;
    overflow-y: scroll;
}

Card {
    color: var(--accentDark);
}

.comment-card {
  height: auto !important;
    width: 30vh;
    background-color: var(--light) !important;
    box-shadow: var(--boxShadow1) !important;
    border: none !important;
}

.comment-card .header,
.comment-card .meta,
.comment-card .description {
  color: var(--darkest) !important;
}

.comment-card .header,
.comment-card .meta,
.comment-card .description {
  color: var(--darkest) !important;
}



.contact-button, reveal-container {
    height: 35vh;
    width: 50vh;
}

  


.avatar-option-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 20vw;
    height: 25vh;
}

.avatar-scrollable-div {
    width: 20vw;
    height: 80vh;
}

.reveal-container, .message-form, .shout-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    color: var(--light);
    height: 100%;
}

.message-form, .shout-form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-content: center;
    align-items: center;
    color: var(--light)
}

.message-form Input, .shout-form Input {
    align-content: center;
    width: 40vh;
}

.message {
    height: 10vh;
    width: 40vh;
}


.circle-button {
margin:1rem;
}

.shout-image-container img, .whisper-image-container img {
    filter: grayscale(100%) brightness(0.2) hue-rotate(140deg);
  }
  
  .shout-image-container::before, .whisper-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 105%;
    height: 95%;
    background-color: var(--accentLight);
    opacity: 1;
    mix-blend-mode: multiply;
    z-index: 2;
  }

  .whisper-image-container img::before {
    height:100%;
    margin: 0;
    padding: 0;
  }
  
  .overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    border-radius: 0.5rem;
    z-index: 1;
  }
  
  .shout-image-container img::before, .whisper-image-container img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--accentLight);
    mix-blend-mode: multiply;
    opacity: 0.;
  }


  @media only screen and (max-width: 800px) {
    /* Add your mobile styles here */
    .shout-image-container img, .whisper-image-container img {
      filter: grayscale(100%) brightness(0.1);
      width: 90vw; /* make images full width */
    }
    
    .overlay-text {
      font-size: 1.1rem; /* decrease font size */
      border-radius: 0.3rem; /* decrease border radius */
    }

    .button-div {
      width: 90vw;
    }

    .contact-container {
        height: auto;
        flex-direction: column;
    }
    .comment-container {
      overflow: hidden; 
      overflow-y: scroll;
      padding-top: 50vw ;
      max-height: 100vh;
      width: 100vw;
      padding: 5vw;
      height: 100vh;
    }

    Input {
        width: 80vw;
    }

    .comment-card {
      display: flex;
        height: auto !important;
        margin: 5vw;
    }
  }

