.about-container {
  display: flex;
  flex-direction: row;
  background-color: var(--darkest);
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  color: var(--light);
}

.about-text-img-container {
  display: flex;
  flex-direction: row;
  /* border: solid salmon; */
  width: 80vw;
  height: 80vh;
  justify-content: center;
  align-content: center;
  z-index: 2;
}

.about-text-container {
  margin: 5vh;
  overflow-y: scroll;
  /* border: solid rgb(191, 181, 180); */
  width: 60vw;
  height: 70vh;
  background-color: transparent;
  padding-right: 0vw;
  box-shadow: inset 0px 40px 40px -40px rgba(31, 33, 34, 0.8), inset 0px -80px 80px -80px rgba(31, 33, 34, 0.8);
  /* direction: rtl; */
}

.about-text {
  overflow-y: scroll;
  font-size: 1.5rem;
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: transparent;
  z-index: 2
}



.reveal-container {
  height: 100%;
  margin: 0;
  padding: 0;
}




.about-bg-image {
  justify-self: center;
  background-image: url('https://png2.cleanpng.com/sh/3220b81c806c51e4693f61cfac1a3552/L0KzQYm3VcA2N5t7fZH0aYP2gLBuTfZqdpJxRdhqboTkg8q0mPl3NZlqeehuboP6ccPrTfZqdpJxRdhqboTkg8q0lvlqNZMyTdNuZEa4cYO6Wck6PmMzUKgANkG4QIO4VcI2PWE8TKs5MkO4SXB3jvc=/kisspng-final-fantasy-xiv-heavensward-final-fantasy-vii-b-5aed65a2399962.8656150215255074902359.jpeg'); 

  background-size: cover;
  background-position: center;
  filter: grayscale(100%);
  opacity: .5;
  position: relative;
  width: 0vw;
  height: 50vw;
  z-index: 9999;
  margin: 0vh;
  right: 0vw;
}


.about-text::-webkit-scrollbar {
  width: 0px;
  background: var(--dark);
}

.about-text-container::-webkit-scrollbar {
  width: 5px;
  background: rgba(84, 168, 36, 0.201);
}

.about-text-container::-webkit-scrollbar-track {
  background: var(--dark);
}

.about-text-container::-webkit-scrollbar-thumb {
  background: var(--accentLight);
  border-radius: 5px;
}

.about-text-container::-webkit-scrollbar-thumb:hover {
  background: grey;
}


/* mobile styling ------------------ */

@media only screen and (max-width: 900px) {

  .about-container {
    flex-direction: row;
    align-items: cover;
  }

  .about-text-container {
    max-width: 100vw;

    min-width: 100vw;
    height: 70vh;
    overflow-y: scroll;
    display: flex;
    overflow-x: hidden;
    will-change: transform;
    -webkit-overflow-scrolling: touch;
  }

  .about-text-img-container {
    width: 100vw;
    height: 90vh;
    margin-bottom: 0;
    z-index: 2
  }

  .about-text {
    font-size: 1rem;
    z-index: 2;
  }

  .about-bg-image {
    background-color: transparent;
    width: 100vw;
    height: 90vh;
    position: absolute;
  }

}


