/* Portfolio Section */

#portfolio-container {
    display:inline-grid;
    text-align: center;
    height: 100vh;
    width: 100vw;
    scroll-snap-align: end;
    padding-left: 5%;
    padding-right: 5%;
}



.code-gif img {
    width: 5vw;
    height: 10vh;
    border-radius: 1rem;
    filter: grayscale(100%);
}
.code-gif {
    width:5vw;
    height: 100%;
}

.p-card {
    border: 1px solid salmon;
    width: 20%;
    height: 20%;
}
