.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 5rem;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.sidebar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sidebar-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.sidebar-nav li {
    margin: 1rem 0;
    text-align: center;
}

.sidebar-nav li.active a {
    font-weight: bold;
}

.sidebar-nav a {
    color: #000;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
}


.sidebar-line {
    position: absolute;
    top: 0;
    left: 5rem;
    height: 100%;
    width: 0.25rem;
    /* background-color: #0077ff; */
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav li.active ~ .sidebar-line {
    transform: translate(0, calc(var(--index, 0) * 100%));
    color: red;
}
