.portfolio-container {
    display: flex;
    flex-wrap: wrap;
    overflow:auto;
    height: 100vh;
    width: 100vw;
    background-color: var(--darkest);
    scroll-behavior: smooth;
}

.portfolio-image-div, .portfolio-info-div {
    height:30vh;
    width: 50vh;
    aspect-ratio: 1/1;
    position: relative;
}

.portfolio-img {
    height: 30vh;
    width: 50vh;
    object-fit: cover;
    overflow: hidden;
    z-index:1

}

.portfolio-image-div {
    /* border: solid salmon 5px; */
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
}

.portfolio-info-div {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--darkest75);
    visibility: hidden;
    z-index: 1;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}




/*Flip styling ------------------ */
.portfolio-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
  }
  
  .portfolio-card {
    position: relative;
    cursor: pointer;
  }





  
  .portfolio-flip-container {
    perspective: 700px;
  }
  
  .portfolio-flip-card {
    position: relative;
    height: 30vh;
    width: 50vh;
    transform-style: preserve-3d;
    transition: transform 1s;
  }
  
  .portfolio-flip-card.flipped {
    transform: rotateX(180deg);
  }
  
  .portfolio-image-div,
  .portfolio-info-div {
    box-shadow: var(--boxShadow1);
    position: absolute;
    top: 0;
    left: 0;
    backface-visibility: hidden;
    border-radius: 10px;
  }

  
.portfolio-info-div {
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  text-align: center;
  background-color: var(--accentDark50);
  color: var(--light);
  font-size: 1.5rem;
  transform: rotateX(180deg);
  z-index: 5;
}
  

  .portfolio-links {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-top: 1rem;
  }
  
  .portfolio-links a {
    background-color: #333;
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    text-decoration: none;
  }
  
    .portfolio-flip-card.flipped .portfolio-info-div {
        opacity: 1;
        visibility: visible;
    }


/* Icons style÷ */

.portfolio-tech-div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
    position: relative;
  }
  
  .portfolio-tech-icon {
    height: 3rem;
    width: 3rem;
    color: var(--light);
  }
  
  .animate-icon {
    transform: translateY(20px);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }
  
  .animate-icon.animate {
    transform: translateY(0);
    opacity: 1;
  }



/* animation section  */
@keyframes slide-in-right {
    0% {
      transform: translateX(100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }



/* ----------------------------------------- */






/* mobile styling ------------------ */
@media only screen and (max-width: 800px) {
    .portfolio-container {
        display: flex;
        padding-top: 10vw;
        padding-bottom: 10vh;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        justify-content: space-evenly;
        align-items: center;
        background-color: var(--darkest);
        padding: 0;
    }

    .portfolio-image-div, .portfolio-info-div {
        width: 90vw;
        height: 54vw;
        position: relative;
        /* margin-left: auto;
        margin-right: auto; */
    }

    .portfolio-img {
        object-fit: cover;
        border-radius: 10px;
        width: 90vw;
        height: 54vw;
    }

    .portfolio-info-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 90vw;
        height: 54vw;
        background-color: var(--darkest75);
        justify-content: space-between;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        transform: translateY(100%);
        border-radius: 10px;
    }

    .portfolio-container button {
        border-radius: 50%;
        background-color: var(--accentLight);
    }

    .portfolio-image-div:hover .portfolio-info-div {
        opacity: 1;
        visibility: visible;
        transform: translateY(0%);
    }

    .portfolio-card {
      display: flex;
      flex-wrap: wrap;
        position: relative;
        cursor: pointer;
        height: 54vw;
        /* margin: 1px; */
        max-width: 90vw !important;
    }

    .portfolio-flip-container {
        perspective: 700px;
      }

      
      /* .portfolio-flip-card {
        position: relative;
        height: 54vw;
        width: 90vw;
        transform-style: preserve-3d;
        transition: transform 1s;
      } */

      

      .portfolio-info-div {
        height: 54vw;
        width: 90vw;
        position: absolute;
        top: 0;
        left: 0;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: var(--accentDark50);
        color: var(--light);
        font-size: 1.5rem;
        transform: rotateX(180deg);
        margin: 0;
        padding: 0;
      }
}

  








