:root {
  --darkest: #222529;
  --darker: #474B4F;
  --darker50: #474b4f7e;
  --dark: #6B6E70;
  --light: #808d97;
  --lighter: #bed3e2;
  --lightest: #d2ebfe;
  --accentLight: #86C232;
  --accentMid: #61892F;
  --accentDark: #354520;
  --accentLight75: #86c23289;
  --accentDark50: #3545207c;
  --accentDark50: #354520c4;
  --darkest75: #222629c7;
  --boxShadow1:
  16px 16px 17px rgba(0, 0, 0, 0.35)
;
--boxShadow2:
30px 30px 30px rgba(0, 0, 0, 0.35)
;
}

/* #FFB03A; yellow*/

.pages, html, body, section {
  background-color: var(--darkest);
}

.divider, Divider {
  background-color: var(--darkest);
  border: none;
  z-index: 1;
  color: salmon;
}

.transparent {
  background-color: var(--darkest);
}


body, html {
  height: 100%;
  width: 100%;
  font-size: 1.1rem;
  padding: 0;
  background-color: var(--darkest);

}


.spacer1 {
  height: 10vw;
  background-color: var(--darkest)
}
.spacer2 {
  height: 20vw;
  background-color: var(--darkest)
}


/* #content-body-container::-webkit-scrollbar {
  display: none;
} */

body, html ::-webkit-scrollbar {
  display: hidden;
}


/* Navbar section */



#page {
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  vertical-align: center;
  scroll-snap-type: y mandatory;
  height: 100%;
  overflow-y: scroll;
  overflow: hidden;
}



/* Intro section */



.pages {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}







li {
  list-style: none;
}

/* Content body container section */

#content-body-container {
  overflow: hidden;
  height: 100dvh;
  width: 100dvw;
  /* border-style: solid;
  border-color: whitesmoke; */
  position:relative;
  align-content: center;
  text-align: center;
  overflow-y: auto;
}


/* About Section */

#about-container {
  background-image: 
  linear-gradient(rgba(154, 224, 14, 0.418), rgba(22, 22, 22, 0.423))
  ,
  url("https://w0.peakpx.com/wallpaper/187/642/HD-wallpaper-plants-tropical-fern-bushes-dark.jpg")
  /* url("https://images.wallpaperscraft.com/image/single/plant_leaves_green_153261_2560x1024.jpg") */
  ;
;

  min-height: 20%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  display: block;
  text-align: center;
  height: 50%;
  width: 100vw;
  /* border: 1px solid white; */
  margin: 0;
} 

#shadow-host-companion {
  display: none;
}









/* @media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
} */

/* #pf1 {
  transition-delay: .2s;
}

#pf2 {
  transition-delay: .4s;
}

#pf3 {
  transition-delay: .6s;
} */




/* Contact Section  */

#contact-container {
  display: block;
  text-align: center;
  height: 50%;
  width: 100vw;
}


/*Sentence*/
.sentence {
  text-align: left;
}

/*Wrapper*/
.wrapper {
  margin: 0px;
  padding: 0px 0px;
  position: relative;
  min-width: 80%;
}



/*topToBottom Animation*/
@-webkit-keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes topToBottom {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
    -webkit-transform: translateY(-50px);
  }
  10% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  25% {
    opacity: 1;
    -webkit-transform: translateY(0px);
  }
  30% {
    opacity: 0;
    -webkit-transform: translateY(50px);
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

body, html {
  height: 100%;
  width: 100%;
  font-size: 1.1rem;
  padding: 0;
  background-color: var(--darkest);
  /* hide the scrollbar */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
}

/* Webkit-based browsers (e.g., Chrome, Safari) */
::-webkit-scrollbar {
  display: none;
}

/* global mobile styles */

.scroll-button {
  height: 5vh;
  z-index: 2;
}

@media only screen and (max-width: 800px) {


}
