/* Navbar.css */

#navbar-container {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* opacity: .5; */
    z-index: 999;
    height: 6vh;
    margin: 1vh;
    text-align: center;
}

.nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 20rem;
    width: 10vw;
    border: .2rem solid var(--accentLight);
    cursor: pointer;
    background-color:rgba(41, 45, 48, 0.936);
    color: var(--accentLight);
    box-shadow: var(--boxShadow1);
    transition: transform 0.3s ease;
  }

  .nav-item:hover {
    background-color: var(--accentLight);
    color: var(--darkest);
    box-shadow: var(--boxShadow2);
    transform: scale(1.2);
  }



  /* mobile styling
  ------------------- */
  
  @media only screen and (max-width: 900px) {
    #navbar-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      text-align: center;
    }
  
    .nav-item {
      margin: 0;
      padding: 0;
      height: 6vw !important;
    }


  }
  