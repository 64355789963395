.intro-page {
    background-color: var(--darkest);
    color: var(--light) !important;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding:0;
}


section {
    height: 100vh; /* Set the height of the container element */
    overflow-y: scroll; /* Enable scrolling */
    scroll-snap-type: y mandatory; /* Enable snap scrolling */
  }
  
  section {
    height: 100vh; /* Set the height of each section element */
    scroll-snap-align: start; /* Snap to the top of the section */
  }


#Keino {
    margin: 0;
    font-size: 150px;
    color: var(--accentLight);
    /* color: #FFB03A; */
    font-family: "Telford Town", sans-serif;
    letter-spacing: 0px;
    display: inline-block;
    align-self: center;
    text-shadow: var(--boxShadow1);
}

.words {
    font-size: 2.5rem;
}

#intro {
    text-align: center;
}

.wrapper {
    display: inline-block;
    position: relative;
    height: 10vh;
}

.splash {
    margin-top: 12%;
    margin-bottom: 12%;
    text-align: center;
    font-size: 0.1rem;
    cursor: pointer;
}

.slidingVertical {
    height: 30vh;
    padding:1rem;
}


/* Animation for the menu slide in */
@keyframes slide-in {
    from {
        transform: translateX(150%);
    }
    to {
        transform: translateX(0);
    }
}

.nav-item:nth-child(1) {
    animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.nav-item:nth-child(2) {
    animation: slide-in 1.1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.nav-item:nth-child(3) {
    animation: slide-in 1.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.nav-item:nth-child(4) {
    animation: slide-in 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

/*Vertical Sliding*/
.slidingVertical {
    display: inline;
    text-indent: -.4rem;
}

.slidingVertical span {
    animation: topToBottom 12.5s linear infinite 0s;
    -ms-animation: topToBottom 12.5s linear infinite 0s;
    -webkit-animation: topToBottom 12.5s linear infinite 0s;
    opacity: 0;
    position: absolute;
    font-style: italic;
    background-image: linear-gradient(
        60deg,
        /* var(--accentLight), */
        var(--light),
        var(--accentDark)

    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 2.5rem;
    margin-top: -1%;
    padding: 1%;
}

.slidingVertical span:nth-child(2) {
    animation-delay: 2.5s;
    -ms-animation-delay: 2.5s;
    -webkit-animation-delay: 2.5s;
}

.slidingVertical span:nth-child(3) {
    animation-delay: 5s;
    -ms-animation-delay: 5s;
    -webkit-animation-delay: 5s;
}

.slidingVertical span:nth-child(4) {
    animation-delay: 7.5s;
    -ms-animation-delay: 7.5s;
    -webkit-animation-delay: 7.5s;
}

.slidingVertical span:nth-child(5) {
    animation-delay: 10s;
    -ms-animation-delay: 10s;
    -webkit-animation-delay: 10s;
}

/* Media query for mobile view */

@media only screen and (max-width: 600px) {
    /* .nav-bar {
        display: flex;
        align-items:center;
        justify-content: center;
        flex-wrap: wrap;
        position: fixed;
        bottom: 0;
        width: 100%;
        height: 30vh;
        overflow: hidden;
    } */
    .nav-bar {
        display: flex;
        align-items: center;
        background-color: transparent;
        justify-content: space-evenly;
        padding: 0rem;
        transition: transform 0.3s ease-out;
        z-index: 1;
        position: fixed;
        height: 15dvh;
        width: 100dvw;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        bottom: 0;
    }

    .nav-item {
        font-size: 0.7rem;
        padding: 0;
        position: relative;
        transform: rotate(0deg);
        margin: 0rem;
        margin-bottom: -1rem;
        width: 9dvh;
        height: 6dvh;
    }

    /* Animation for the menu slide in */
    @keyframes slide-in {
        from {
            transform: translateX(500%);
        }
        to {
            transform: translateX(0);
        }
    }

    .nav-item:nth-child(1) {
        animation: slide-in 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
    }
    .nav-item:nth-child(2) {
        animation: slide-in 1.1s cubic-bezier(0.175, 0.885, 0.32, 1.275)
            forwards;
    }
    .nav-item:nth-child(3) {
        animation: slide-in 1.3s cubic-bezier(0.175, 0.885, 0.32, 1.275)
            forwards;
    }
    .nav-item:nth-child(4) {
        animation: slide-in 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
            forwards;
    }

    .words {
        font-size: 1.5rem;
        text-align: center;
    }

    .long {
        margin-left: -30dvw;
    }

    .splash{
        text-align: center;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .slidingVertical span {
        text-align: center;
        font-size: 1.5rem;
        font-weight: normal;
    }

    .slidingVertical {
        height: 15vh;
    }
}
