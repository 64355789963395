

pre {
    white-space: pre-wrap;
}

.chat-popup-icon {
    width: 50px; /* Adjust the width and height to your desired crop size */
    height: 50px;
    min-height: 50px;
    border-radius: 100%;
    position: relative;
    background-color: transparent;
    overflow: hidden;
    margin: 10px;
}

.chat-popup-icon img {
    object-fit: cover;
    width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chat-popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
}

.chat-box {
    position: fixed;
    bottom: 10px;
    right: 10px;
    width: 300px;
    background: var(--darkest75);
    color: white;
    padding: 10px;
    border-radius: 10px 10px 0 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    z-index: 5;
    opacity: .9;
}

.chat-box h1 {
    color: var(--accentLight75);
    font-size: 20px;
    margin-bottom: 10px;
}

.chat-box ul {
    overflow-y: scroll;
    list-style-type: none;
    padding: 0;
    margin: 0 0 10px 0;
    max-height: 40vh;
}

.chat-box li {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 2px;
    text-align: start;
}

.chat-box li:nth-child(odd) {
    background: var(--accentDark50);
    width: 90%;
    margin-right: 10%;
    justify-items: flex-start;
}

.chat-box li:nth-child(even) {
    background: var(--darker50);
    width: 90%;
    margin-left: 10%;
    align-self: self-end;
    align-items: self-end;
    justify-self: self-end;
}

.chat-box form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto;
}

.chat-box input {
    flex-grow: 1;
    border: none;
    padding: 5px;
    border-radius: 5px;
    margin-right: 10px;
    color: black;
}

.chat-box button {
    background: var(--accentLight75);
    border: none;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 10rem;
    height: 2rem;
    align-self: flex-end;
}
