@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


section {
  height: 100vh; /* Set the height of the container element */
  overflow-y: scroll; /* Enable scrolling */
  scroll-snap-type: y mandatory; /* Enable snap scrolling */
}

section {
  height: 100vh; /* Set the height of each section element */
  scroll-snap-align: start; /* Snap to the top of the section */
}